
import ScrollPane from './ScrollPane.vue'
// @ts-ignore
import path from 'path'
import {Component, Vue} from "vue-property-decorator";
// @ts-ignore
import variables from "@/assets/styles/variables.scss";

@Component({
  components: {ScrollPane},
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    routes() {
      return this.$store.state.useMenuStore.menuList
    },
  },
  watch: {
    //路由
    $route() {
      // @ts-ignore
      this.addTags()
      // @ts-ignore
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        // @ts-ignore
        document.body.addEventListener('click', this.closeMenu)
      } else {
        // @ts-ignore
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    //初始tab
    // @ts-ignore
    this.initTags()
    // @ts-ignore
    this.addTags()
  }

})
export default class Tabbar extends Vue {
  visible=false
  top='10px'
  left= 0
  selectedTag={}
  affixTags=[]

  //选中tab
  isActive(route:any) {
    return route.path === this.$route.path
  }
  //样式
  variables() {
    return variables;
  }
  //选中tab 样式
  activeStyle(tag:any) {
    if (!this.isActive(tag)) return {};
    return {
      // @ts-ignore
      "background-color": this.variables.menuColorActive,
      // @ts-ignore
      "border-color": this.variables.menuColorActive,
    };
  }
  isAffix(tag:any) {
    return tag.meta && tag.meta.affix
  }
  //tab 是否是第一个
  isFirstView() {
    try {
      // @ts-ignore
      return this.selectedTag.fullPath === this.visitedViews[1].fullPath || this.selectedTag.fullPath === '/index'
    } catch (err) {
      return false
    }
  }
  //tab 是否是最后一个
  isLastView() {
    try {
      // @ts-ignore
      return this.selectedTag.fullPath === this.visitedViews[this.visitedViews.length - 1].fullPath
    } catch (err) {
      return false
    }
  }
  //初始tab数据结构
  filterAffixTags(routes:any, basePath = '/') {
    let tags:any[] = []
    routes.forEach((route:any) => {
      if (route.meta && route.meta.affix) {
        const tagPath = path.resolve(basePath, route.path)
        tags.push({
          fullPath: tagPath,
          path: tagPath,
          title: route.meta.title,
          name: route.name,
          meta: {...route.meta}
        })
      }
      if (route.children) {
        const tempTags = this.filterAffixTags(route.children, route.path)
        if (tempTags.length >= 1) {
          tags = [...tags, ...tempTags]
        }
      }
    })
    return tags
  }
  //初始tab
  initTags() {
    // @ts-ignore
    const affixTags = this.affixTags = this.filterAffixTags(this.routes)
    for (const tag of affixTags) {
      if (tag.name) {
        this.$store.dispatch('addVisitedView', tag)
      }
    }
  }
  //添加tab
  addTags() {
    const {name} = this.$route
    if (name && name != 'home') {
      this.$store.dispatch("addView", this.$route)
    }
    return false
  }
  moveToCurrentTag() {
    this.$nextTick(() => {
      const tags = this.$refs.tag
      // @ts-ignore
      for (const tag of tags) {
        if (tag.to.path === this.$route.path) {
          // @ts-ignore
          this.$refs.scrollPane.moveToTarget(tag)
          if (tag.to.fullPath !== this.$route.fullPath) {
            this.$store.dispatch('updateVisitedView', this.$route)
          }
          break
        }
      }
    })
  }
  // 刷新当前tab页签
  refreshSelectedTag(view:any) {
    const {path, query, matched} = this.$router.currentRoute;
    if (view === undefined) {
      matched.forEach((m) => {
        // @ts-ignore
        if (m.components && m.components.default && m.components.default.name) {
          // @ts-ignore
          if (!['Layout', 'ParentView'].includes(m.components.default.name)) {
            // @ts-ignore
            view = {name: m.components.default.name, path: path, query: query};
          }
        }
      });
    }
    return this.$store.dispatch('delCachedView', view).then(() => {
      const {path, query} = view
      this.$router.replace({
        path: '/redirect' + path,
        query: query
      })
    })
  }
  //关闭当前tab
  closeSelectedTag(view:any) {
    this.closePage(view).then(({visitedViews}) => {
      if (this.isActive(view)) {
        this.toLastView(visitedViews, view)
      }
    })
  }
  //关闭 right边tab
  closeRightTags() {
    this.closeRightPage(this.selectedTag).then(visitedViews => {
      if (!visitedViews.find((i:any) => i.fullPath === this.$route.fullPath)) {
        // @ts-ignore
        this.toLastView(visitedViews)
      }
    })
  }
  //关闭 left边tab
  closeLeftTags() {
    this.closeLeftPage(this.selectedTag).then(visitedViews => {
      if (!visitedViews.find((i:any) => i.fullPath === this.$route.fullPath)) {
        // @ts-ignore
        this.toLastView(visitedViews)
      }
    })
  }
  //关闭其他tab
  closeOthersTags() {
    this.closeOtherPage(this.selectedTag).then(() => {
      this.moveToCurrentTag()
    })
  }
  //关闭全部tab
  closeAllTags(view:any) {
    this.closeAllPage().then(({visitedViews}) => {
      // @ts-ignore
      if (this.affixTags.some(tag => tag.path === this.$route.path)) {
        return
      }
      this.toLastView(visitedViews, view)
    })
  }
  //操作tab
  toLastView(visitedViews:any, view:any) {
    const latestView = visitedViews.slice(-1)[0]
    if (latestView) {
      this.$router.push(latestView.fullPath)
    } else {
      if (view.name === 'home') {
        // to reload home page
        this.$router.replace({path: '/redirect' + view.fullPath})
      } else {
        this.$router.push('/')
      }
    }
  }
  //显示tab menu
  openMenu(tag:any, e:any) {
    const menuMinWidth = 105
    const offsetLeft = this.$el.getBoundingClientRect().left
    // @ts-ignore
    const offsetWidth = this.$el.offsetWidth
    const maxLeft = offsetWidth - menuMinWidth
    const left = e.clientX - offsetLeft + 15

    if (left > maxLeft) {
      this.left = maxLeft
    } else {
      this.left = left
    }
    this.visible = true
    this.selectedTag = tag
  }
  //关闭
  closeMenu() {
    this.visible = false
  }
  //滚动
  handleScroll() {
    this.closeMenu()
  }

  // 关闭指定tab页签
  closePage(obj:any) {
    if (obj === undefined) {
      return this.$store.dispatch('delView', this.$router.currentRoute).then(({lastPath}) => {
        return this.$router.push(lastPath || '/');
      });
    }
    return this.$store.dispatch('delView', obj);
  }
  // 关闭所有tab页签
  closeAllPage() {
    return this.$store.dispatch('delAllViews');
  }
  // 关闭左侧tab页签
  closeLeftPage(obj:any) {
    return this.$store.dispatch('delLeftTags', obj || this.$router.currentRoute);
  }
  // 关闭右侧tab页签
  closeRightPage(obj:any) {
    return this.$store.dispatch('delRightTags', obj || this.$router.currentRoute);
  }
  // 关闭其他tab页签
  closeOtherPage(obj:any) {
    return this.$store.dispatch('delOthersViews', obj || this.$router.currentRoute);
  }

}
